import React from "react";
import { CSW, CSTitle, A } from "./Base";
// Data //
const roles = [
    {
        roleId: "frontend-ui-engineer",
        roleTitle: "Frontend UI Engineer",
        skills: [
            "Web APIs/Standards",
            "React",
            "TypeScript",
            "Webpack",
            "Frontend tooling",
            "etc.",
        ],
    },
    {
        roleId: "software-engineer",
        roleTitle: "Software Engineer",
        skills: [
            "Distributed Systems",
            "Database Systems",
            "Browser Engineering",
            "Algorithms",
            "C++",
            "Golang",
            "Rust",
            "etc.",
        ],
    },
    {
        roleId: "designer",
        roleTitle: "Designer",
        skills: ["UX/UI", "Product Design", "Graphics and Branding"],
    },
    {
        roleId: "ai-ml-engineer",
        roleTitle: "AI/ML Engineer",
        skills: [
            "NLP",
            "Search/Information Retrieval",
            "Transformers",
            "LLMs",
            "Prompt/Context Engineering",
        ],
    },
    {
        roleId: "research-swe",
        roleTitle: "Research Software Engineer",
        skills: [
            "Information Sciences",
            "Human-Computer Interaction (HCI)",
            "User Interfaces",
            "Information Retrieval",
            "Semantic Web",
            "AI/ML/DL",
            "NLP and LLMs",
            "Signal Processing",
            "and more!",
        ],
    },
];
// Views //
const CareersSection = () => {
    return (React.createElement(CSW, { styles: { gap: "20px" } },
        React.createElement(CSTitle, null, "Careers:"),
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "0px 4px",
            } },
            React.createElement("p", null, "We're always looking for problem-solvers who are passionate about building exceptional products to join us on this journey!"),
            React.createElement("p", null, "Please stay tuned for more information about our open positions."),
            React.createElement("div", { style: {
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                } },
                React.createElement("p", null, "A few roles we'll soon be hiring for include:"),
                React.createElement(OpenRoles, null)),
            React.createElement("p", null,
                "In the meantime, if you'd like to get in touch with us, please feel free to drop us an ",
                React.createElement(A, { href: "mailto:hello@octobase.org", text: "email" }),
                "."))));
};
const OpenRoles = () => {
    return (React.createElement("div", { style: {
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            fontSize: "12px",
            fontFamily: "monospace",
            lineHeight: "1.58",
            color: "#333333",
            wordSpacing: "-0.8px",
            padding: "0px 8px",
        } }, roles.map((role) => (React.createElement(Role, { key: role.roleId, role: role })))));
};
const Role = ({ role }) => {
    const { roleTitle, skills } = role;
    return (React.createElement("div", { style: {
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            alignItems: "baseline",
        } },
        React.createElement("span", { style: { fontWeight: "bold", whiteSpace: "nowrap" } },
            "- ",
            roleTitle),
        skills && skills.length > 0 && (React.createElement("span", { style: {
                fontSize: "12px",
                // maxWidth: "680px",
                wordWrap: "normal",
                whiteSpace: "normal",
                textAlign: "left",
                overflowWrap: "break-word",
            } },
            "(",
            skills.join(", "),
            ")"))));
};
export { CareersSection };
